<template>
  <div>
    <add-adjustment></add-adjustment>
  </div>
</template>

<script>
import AddAdjustment from './AddAdjustment.vue';

export default {
  components: {
    AddAdjustment,
  },

  data() {
    return {};
  },
};
</script>
